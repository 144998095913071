@import url('https://fonts.googleapis.com/css2?family=Comfortaa:wght@300');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&family=Playwrite+NG+Modern:wght@100..400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Changa:wght@200..800&family=Open+Sans:ital,wght@0,300..800;1,300..800&family=Playwrite+NG+Modern:wght@100..400&display=swap');
.des{
    font-family: 'Comfortaa', sans-serifs;
    margin-left:25%;
}
.intro{
    font-family: "Playwrite NG Modern", cursive;
    color:violet;
    margin-right: 10vw;
    
}
.description{
    margin-left:30%;
    
}
.btn{
    margin-left:30%;
    margin-bottom: 10px;
}
.label{
    font-family: "Changa", sans-serif;
}
.name{
    font-family: "Open Sans", sans-serif;
    font-optical-sizing: auto;
    font-weight: 700;
    font-style: normal;
}
