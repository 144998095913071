*{
  box-sizing: border-box;  
}
.reading h2{
    color: powderblue;
    margin-left: 30px;
}
.books{
    position: absolute;
    left:5vw;
    top:20vh;
    border: 1px white;
    border-style: solid;
    width: 80%;
    
    

}
.books img{
    max-width: 30vw;
    max-height: 25vh;
    margin-bottom: 50px;
    padding: 20px;
}
.books a{
    position: relative;
    left: -7rem;
    bottom:10px;
    
    text-decoration: none;
    border: 1px black;
    border-style: solid;
    padding:5px;
    color:thistle;
    background-color: teal;

}
.books h3{
    margin-left:20px;
    color:tomato;
}

@media screen and (max-width:480px) {
    .books img{
        margin-left: 60px;
    }
}
.movies{
    position: relative;
    top:20rem;
}
.movies img{
    width:10vw;
    height: 20vh;
}
