*{
    box-sizing:border-box;
}
.social{
    position: absolute;
    left:15vw;
    top:28vh;
    border: 1px white;
    border-style: solid;
    max-width: 70%;
    max-height: 70%;
    display: flex;
    flex-wrap: wrap;
}
.social img{
    max-width: 30vw;
    max-height: 30vh;
    margin-bottom: 20px;
    padding: 20px;
}
.social a{
    position: relative;
    
    bottom:20px;
}
@media screen and (max-width:560px) {
    .social a{
        top:6rem;
        left:-4rem;
    }
    .social img{
        top:6rem;
        left:-2rem;
    }
}

@media screen and (min-width:450px) {
    .social a{
        top:12rem;
        left:-7rem;
    }
}
