.nav{
  display: flex;
  flex-direction: row;
  gap:1vw;
  border: 1px solid green;
  padding: 1vh;
  font-size: large;
  font-weight: 500;
  list-style-type: none;
  text-decoration: none;
  text-transform: none;
  margin-bottom: 10px;
  
}
.nav li{
  margin-left: 2vw;
}
.body1{
  width: 100%;
  height: 100%;
  border: 1px solid white;
}
.bodynav h2{
  display: flex;
  flex-wrap: nowrap;
  margin-left:40vw;
}
.ganeshimg{
  height: 20%;
  width: 20%;
  display: flex;
  flex-wrap: nowrap;
  flex-grow:inherit;
  margin-left: 35%;
  margin-bottom: 2vh;
  padding:10px;
  border: 1px solid white;
}
.description {
  margin-left: 20vw;
  
}
